import { useLocation } from "@reach/router";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Header from "../components/Header";
import SideNav, { MobileButton } from "../components/Docs/SideNav";
import Navigation from "../components/Docs/Navigation";
import { DOCS_HEADER_HEIGHT } from "../components/Header/Header";

const Container = styled.div`
  overflow-x: hidden;
  height: 100vh;
  padding-bottom: 5rem;
  background-color: var(--primary);
`;

const ContentContainer = styled.section`
  margin-top: calc(var(--m) + ${DOCS_HEADER_HEIGHT});
  display: flex;
  justify-content: space-between;
  position: relative;
`;

interface DocsLayoutProps {
  children: ReactNode;
  path: string;
}

const DocsLayout = ({ children, path }: DocsLayoutProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [headerNavOpen, setHeaderNavOpen] = useState(false);

  const { hash } = useLocation();

  useEffect(() => {
    headerNavOpen && setSideNavOpen(false);
  }, [setSideNavOpen, headerNavOpen]);

  useEffect(() => {
    sideNavOpen && setHeaderNavOpen(false);
  }, [setHeaderNavOpen, sideNavOpen]);

  useEffect(() => {
    if (containerRef.current && hash.length === 0) {
      containerRef.current.scrollTo(0, 0);
    }

    setSideNavOpen(false);
    setHeaderNavOpen(false);
  }, [path]);

  return (
    <Container ref={containerRef}>
      <MobileButton
        open={sideNavOpen}
        setOpen={() => setSideNavOpen(!sideNavOpen)}
      />
      <Header
        open={headerNavOpen}
        setOpen={setHeaderNavOpen}
        headerContent={<Navigation />}
      />
      <ContentContainer>
        <SideNav open={sideNavOpen} />
        {children}
        <div />
      </ContentContainer>
    </Container>
  );
};

export default DocsLayout;
