import { motion } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import React, { useMemo } from "react";
import styled from "styled-components";

import { useWindowSize } from "../../../hooks";
import { M_BREAKPOINT } from "../../../styles";
import { DOCS_HEADER_HEIGHT } from "../../Header/Header";
import Logo from "../../Header/Logo";

import Item, { Node } from "./Item";

const Container = styled(motion.nav)<{ open: boolean }>`
  margin-top: calc(calc(${DOCS_HEADER_HEIGHT} * -1) - var(--xl));
  padding: var(--m);
  padding-left: 0;
  padding-top: 0;
  min-width: 12.5rem;
  z-index: 2;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: max-content;

  @media (max-width: ${M_BREAKPOINT}px) {
    position: fixed;
    top: ${DOCS_HEADER_HEIGHT};
    margin-top: 0;
    z-index: 3;
    height: 100vh;
    width: 100vw;
    min-width: auto;
    background-color: var(--secondary);
  }

  & > :first-child {
    padding: var(--m) 0;
  }
`;

const NavItems = styled.ul`
  margin-top: var(--xl);
  padding-left: var(--sGutter);
`;

const LogoContainer = styled.div`
  height: ${DOCS_HEADER_HEIGHT};
  display: flex;
  align-items: center;
  margin-left: var(--sGutter);

  @media (max-width: ${M_BREAKPOINT}px) {
    display: none;
  }
`;

const variants = {
  closed: {
    transform: "translateX(100%)",
    opacity: 0.9,
  },
  open: {
    transform: "translateX(0%)",
    opacity: 1,
  },
};

export interface Doc {
  title: string;
  pages: {
    title: string;
    url: string;
  }[];
}

const query = graphql`
  {
    allMdx {
      nodes {
        frontmatter {
          title
        }
        slug
      }
    }
  }
`;

interface Query {
  allMdx: {
    nodes: Node[];
  };
}

interface SideNavProps {
  open: boolean;
}

const SideNav = ({ open }: SideNavProps) => {
  const data = useStaticQuery<Query>(query);
  const { width } = useWindowSize();
  const animate = width <= 1000 && !open ? "closed" : "open";

  const categories = useMemo(() => {
    return data.allMdx.nodes.reduce(
      (ctx, node) => {
        const [cat] = node.slug.split("/");

        cat in ctx ? ctx[cat].push(node) : (ctx[cat] = [node]);

        return ctx;
      },

      {} as { [key: string]: Node[] }
    );
  }, []);

  return (
    <Container
      open={open}
      variants={variants}
      animate={animate}
      initial={
        typeof window !== "undefined" && window.innerWidth <= 1000
          ? "closed"
          : "open"
      }
      transition={{ bounce: false }}
      aria-hidden={!open}
    >
      <LogoContainer>
        <Logo />
      </LogoContainer>
      <NavItems>
        {categories &&
          Object.entries(categories).map((group, i) => (
            <Item key={i} nodes={group} />
          ))}
      </NavItems>
    </Container>
  );
};

export default SideNav;
