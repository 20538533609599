import React from "react";
import NToggle from "react-toggle";
import styled from "styled-components";

const IconContainer = styled.span`
  height: 10px;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface IconProps {
  children: string;
}

const Icon = ({ children }: IconProps) => (
  <IconContainer>{children}</IconContainer>
);

const Container = styled.div`
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface ToggleProps {
  isToggled: boolean;
  setIsToggled: () => void;
}

const Toggle = ({ isToggled, setIsToggled }: ToggleProps) => {
  return (
    <Container>
      <NToggle
        icons={{
          checked: <Icon>🌜</Icon>,
          unchecked: <Icon>🌞</Icon>,
        }}
        defaultChecked={isToggled}
        onChange={() => setIsToggled()}
      />
    </Container>
  );
};

export default Toggle;
