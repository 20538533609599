import styled, { css } from "styled-components";

export const absoluteFillObject = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const FlexPlaceHolder = styled.div.attrs(() => ({
  "aria-hidden": true,
}))<{ visibleBelow: string }>`
  display: block;

  @media (min-width: ${({ visibleBelow }) => visibleBelow}) {
    display: none;
  }
`;

export const hrefStyles = css`
  cursor: pointer;
  border-bottom: 1px solid transparent;
  color: inherit;

  &:hover {
    color: var(--accent);
    border-bottom: 1px solid var(--accent);
  }
`;

export const highlightHref = css`
  background: var(--accentOpaque);
`;
