import { useLocation } from "@reach/router";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { Link as LinkIcon } from "react-feather";

import { M_BREAKPOINT } from "../../styles";

import { ComponentType } from "./Elements";

const LinkContainer = styled.a`
  transition: opacity 50ms linear;
  opacity: 0;
  position: absolute;
  left: calc(var(--xl) * -1);
  top: 0;
  padding: 0 var(--m);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  @media (max-width: ${M_BREAKPOINT}px) {
    left: calc(var(--l) * -1);
  }

  &:hover {
    opacity: 1;
  }
`;

const targetStyles = {
  borderRadius: "var(--m)",
  border: "2px solid orange",
  backgroundColor: "rgba(255, 165, 0, 0.2)",
  position: "relative",
} as const;

export interface TargetableProps {
  children: ReactNode;
  id?: string;
}

const Targetable = (
  Component: ComponentType,
  { id, children }: TargetableProps
) => {
  const { hash } = useLocation();

  const style =
    hash === `#${id}` ? targetStyles : ({ position: "relative" } as const);

  return (
    <Component style={style} id={id}>
      {id && (
        <LinkContainer href={`#${id}`}>
          <LinkIcon size={14} color="var(--accent)" />
        </LinkContainer>
      )}
      {children}
    </Component>
  );
};

export default Targetable;
