import { css } from "styled-components";
// override styles for component libs
export default css`
  .leaflet-pane {
    z-index: 1 !important;
  }

  .leaflet-bottom {
    z-index: 1 !important;
  }
`;
