import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";

import SvgLogo from "../SvgLogo";

const Container = styled.div`
  display: flex;
  align-items: center;
  transition: background-color 200ms linear;
  height: min-content;

  & > a {
    margin: calc(var(--m) * -1);
    padding: var(--m);
    border-radius: var(--m);

    & > svg {
      max-width: none;
    }

    &:hover {
      background-color: var(--accentOpaque);
    }
  }
`;

const Slash = styled.span`
  margin: 0 var(--s);
  width: 16px;
  height: 1.25px;
  transform: rotate(-60deg);
  display: inline-block;
  background-color: var(--textColor);
  opacity: 0.75;
`;

const Url = styled.span`
  font-size: 1.15rem;
  font-weight: var(--xBold);
  color: var(--accent);
`;

const Logo = () => {
  const { pathname } = useLocation();
  const [route, setRoute] = useState("");

  useEffect(() => {
    const split = pathname.split("/");

    setRoute(split.includes("events") ? "events" : "docs");
  }, [pathname]);

  return (
    <Container>
      <Link to="/" aria-label="homepage">
        <SvgLogo dark />
      </Link>
      <Slash />
      <Link to={`/${route}`}>
        <Url>{route}</Url>
      </Link>
    </Container>
  );
};

export default Logo;
