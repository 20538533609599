import { RefObject } from "react";

/**
 * is Server Side Rendering environment
 */
export const isSSR = !(
  typeof window !== "undefined" && window.document?.createElement
);

export const getRefElement = <T,>(
  element?: RefObject<Element> | T
): Element | T | undefined | null => {
  if (element && "current" in element) {
    return element.current;
  }

  return element;
};
