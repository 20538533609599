import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { VatsimEvent } from "../../components/Events/Event";

export const VatsimEventsContext = createContext<VatsimEvent[] | undefined>(
  undefined
);

export const useVatsimEvents = () => useContext(VatsimEventsContext);

interface VatsimEventsProviderProps {
  children: ReactNode;
}

const VatsimEventsProvider = ({ children }: VatsimEventsProviderProps) => {
  const [data, setData] = useState<VatsimEvent[]>();

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch(`${process.env.GATSBY_API_URL}/api/events`);
        const d = await response.json();
        setData(d);
      } catch (error) {
        console.log("error fetching events:", error);
      }
    })();
  }, []);

  return (
    <VatsimEventsContext.Provider value={data}>
      {children}
    </VatsimEventsContext.Provider>
  );
};

export default VatsimEventsProvider;
