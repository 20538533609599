import React from "react";
import { Compass, X } from "react-feather";
import styled from "styled-components";

import { M_BREAKPOINT } from "../../../styles";

const Container = styled.div`
  position: fixed;
  padding: var(--ml);
  border-radius: 50%;
  background-color: var(--accent);
  right: 2rem;
  bottom: 2rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 20px;
  cursor: pointer;
  z-index: 4;

  @media (min-width: ${M_BREAKPOINT}px) {
    display: none;
  }
`;

interface MobileButtonProps {
  open: boolean;
  setOpen: () => void;
}

const MobileButton = ({ open, setOpen }: MobileButtonProps) => {
  return (
    <Container
      aria-label="Open mobile navigation"
      role="button"
      onClick={() => setOpen()}
    >
      {open ? (
        <X color="#FFFFFF" size={28} />
      ) : (
        <Compass color="#FFFFFF" size={28} />
      )}
    </Container>
  );
};

export default MobileButton;
