import React from "react";
import { Link } from "@reach/router";
import styled from "styled-components";

import { hrefStyles } from "../../styles";

const links = [] as const;

export const DOCS_NAV_BREAKPOINT = 1150;

const NavItems = styled.ul`
  display: flex;

  @media (max-width: ${DOCS_NAV_BREAKPOINT}px) {
    width: 100%;
    flex-direction: column;
    padding: 0 var(--sGutter);
  }
`;

const NavItem = styled.li`
  transition: background-color 200ms linear;
  border-radius: var(--s);

  &:hover {
    background-color: var(--accentOpaque);
  }

  @media (max-width: ${DOCS_NAV_BREAKPOINT}px) {
    border-bottom: 1px solid var(--borderColor);
    border-radius: 0;

    &:hover {
      background-color: inherit;
    }

    &:last-of-type {
      border-bottom: none;
    }
  }

  & > a {
    padding: var(--m) var(--l);
    color: inherit;
    font-weight: var(--bold);
    display: flex;
    align-items: center;
    font-size: var(--sText);

    :after {
      content: "›";
      margin-left: var(--s);
      color: var(--textColor);
    }

    @media (max-width: ${DOCS_NAV_BREAKPOINT}px) {
      ${hrefStyles}
      padding: var(--xl) 0;
      font-size: 1.25rem;
      font-weight: normal;

      &:hover {
        border-bottom: 1px solid transparent;
      }
    }
  }
`;

const Navigation = () => {
  return (
    <NavItems>
      {links.map(({ link, name }) => (
        <NavItem key={name}>
          <Link to={link}>{name}</Link>
        </NavItem>
      ))}
    </NavItems>
  );
};

export default Navigation;
