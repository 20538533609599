/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { Helmet } from "react-helmet";

import VatsimEventsProvider from "./src/context/VatsimEvents";
import ThemeProvider from "./src/context/Theme";
import { GlobalStyle } from "./src/styles";

export const wrapPageElement = ({ element }) => (
  <>
    <GlobalStyle />
    {element}
  </>
);

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>
    <VatsimEventsProvider>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {element}
    </VatsimEventsProvider>
  </ThemeProvider>
);
