import styled from "styled-components";

import { hrefStyles, highlightHref, M_BREAKPOINT } from "../../styles";
import { DOCS_HEADER_HEIGHT } from "../Header/Header";

import Targetable, { TargetableProps } from "./Targetable";

export const H1 = styled.h1`
  font-weight: var(--xBold);
  font-size: 2.5rem;
  line-height: 2.7rem;
  margin-bottom: var(--l);

  @media (max-width: ${M_BREAKPOINT}px) {
    font-size: 1.75rem;
  }
`;

const StyledH2 = styled.h2`
  font-weight: var(--xBold);
  font-size: 1.75rem;
  line-height: 2rem;
  margin-bottom: var(--m);
  scroll-margin-top: calc(${DOCS_HEADER_HEIGHT} + 1rem);
  position: relative;
  padding: var(--m);
  margin: 0 calc(var(--m) * -1);
  border: 2px solid transparent;

  &:hover > a {
    opacity: 1;
  }
`;

const StyledH3 = styled.h3`
  font-weight: var(--bold);
  font-size: 1.5rem;
  line-height: 1.75rem;
  margin-bottom: var(--s);
  scroll-margin-top: calc(${DOCS_HEADER_HEIGHT} + 1rem);
  position: relative;
  padding: var(--m);
  margin: 0 calc(var(--m) * -1);
  border: 2px solid transparent;

  &:hover > a {
    opacity: 1;
  }
`;

const StyledH4 = styled.h4`
  font-weight: var(--bold);
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: var(--s);
  scroll-margin-top: calc(${DOCS_HEADER_HEIGHT} + 1rem);
  position: relative;
  padding: var(--m);
  margin: 0 calc(var(--m) * -1);
  border: 2px solid transparent;

  &:hover > a {
    opacity: 1;
  }
`;

export const OL = styled.ol`
  list-style-type: decimal;
  margin-bottom: var(--l);

  & > li {
    margin: var(--m) var(--l);
    margin-left: var(--xxl);
    line-height: 1.6em;

    & > strong {
      font-weight: var(--bold);
    }

    @media (max-width: ${M_BREAKPOINT}px) {
      margin-left: var(--m);
    }
  }
`;

export const UL = styled.ul`
  list-style-type: disc;
  margin-bottom: var(--l);

  & > li {
    margin: var(--m) var(--l);
    line-height: 1.6rem;

    & > strong {
      font-weight: var(--bold);
    }

    &::marker {
      ::before {
        width: 1rem;
        font-weight: var(--xBold);
        color: var(--textColor);
        content: "• ";
      }
    }

    @media (max-width: ${M_BREAKPOINT}px) {
      margin-left: var(--m);
    }
  }
`;

const StyledP = styled.p`
  margin: calc(var(--m) * -1);
  padding: var(--m);
  position: relative;
  font-size: 1rem;
  line-height: 1.6em;
  // TODO: not supported on safari
  scroll-margin-top: calc(${DOCS_HEADER_HEIGHT} + 1rem);
  word-break: break-word;

  &:hover > a {
    opacity: 1;
  }

  & > strong {
    font-weight: var(--bold);
  }

  & > em {
    font-style: italic;
  }
`;

export const A = styled.a`
  ${hrefStyles}
  ${highlightHref}
`;

export type ComponentType = typeof H1;
export const P = (props: TargetableProps) => Targetable(StyledP, props);
export const H2 = (props: TargetableProps) => Targetable(StyledH2, props);
export const H3 = (props: TargetableProps) => Targetable(StyledH3, props);
export const H4 = (props: TargetableProps) => Targetable(StyledH4, props);
