import { motion } from "framer-motion";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { useTheme } from "../../context/Theme";
import { useWindowSize } from "../../hooks";
import { absoluteFillObject } from "../../styles";
import { S_BREAKPOINT, XS_BREAKPOINT } from "../../styles/GlobalStyle";
import { DOCS_NAV_BREAKPOINT } from "../Docs/Navigation";

import Logo from "./Logo";
import Toggle from "./Toggle";

const Container = styled(motion.div)`
  width: 100%;

  @media (max-width: ${DOCS_NAV_BREAKPOINT}px) {
    ${absoluteFillObject}
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    pointer-events: none;
    transition: background-color 100ms ease-in-out;
    display: flex;
  }
`;

const Nav = styled(motion.nav)`
  width: 100%;
  margin: 0 var(--l);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  pointer-events: all;

  @media (max-width: ${DOCS_NAV_BREAKPOINT}px) {
    z-index: 3;
    margin: 0;
    width: 80vw;
    height: 100vh;
    background-color: var(--primary);
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (max-width: ${XS_BREAKPOINT}px) {
    width: 85vw;
  }
`;

const Backdrop = styled.div`
  @media (max-width: ${DOCS_NAV_BREAKPOINT}px) {
    width: 20vw;
    height: 100vh;
  }

  @media (max-width: ${XS_BREAKPOINT}px) {
    width: 15vw;
  }
`;

const Header = styled.div`
  box-shadow: 0 2px 4px rgba(3, 27, 78, 0.05);
  background-color: var(--secondary);
  width: 100%;
  min-height: 4rem;
  padding: 0 var(--sGutter);
  display: flex;
  align-items: center;
  display: none;

  @media (max-width: ${DOCS_NAV_BREAKPOINT}px) {
    display: flex;
  }

  @media (max-width: ${S_BREAKPOINT}px) {
    padding: 0 var(--m);
  }
`;

const variants = {
  closed: {
    transform: "translateX(-100%)",
    opacity: 0.9,
  },
  open: {
    transform: "translateX(0%)",
    opacity: 1,
  },
};

interface SlideOverProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  contents?: ReactNode;
}

const SlideOver = ({ open, setOpen, contents }: SlideOverProps) => {
  const { width } = useWindowSize();
  const [isDarkMode, setIsDarkMode] = useTheme();
  const animate = width <= DOCS_NAV_BREAKPOINT && !open ? "closed" : "open";

  return (
    <Container
      style={{
        backgroundColor:
          open && width <= DOCS_NAV_BREAKPOINT
            ? "rgba(0,0,0,0.7)"
            : "rgba(0,0,0,0)",
      }}
    >
      <Nav
        aria-hidden={!open}
        variants={variants}
        animate={animate}
        initial="open"
        transition={{ bounce: false }}
      >
        <Header>
          <Logo />
          <Toggle
            isToggled={isDarkMode}
            setIsToggled={() => setIsDarkMode(!isDarkMode)}
          />
        </Header>
        {contents}
      </Nav>
      <Backdrop
        style={{
          pointerEvents: open ? "auto" : "none",
        }}
        onClick={() => setOpen(false)}
      />
    </Container>
  );
};

export default SlideOver;
