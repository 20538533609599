import React, { ReactNode } from "react";
import { Menu } from "react-feather";
import styled from "styled-components";

import { useTheme } from "../../context/Theme";
import { S_BREAKPOINT } from "../../styles";
import { DOCS_NAV_BREAKPOINT } from "../Docs/Navigation";

import Logo from "./Logo";
import SlideOver from "./SlideOver";
import Toggle from "./Toggle";
import ToggleStyles from "./ToggleStyles";

export const DOCS_HEADER_HEIGHT = "4rem";

const Container = styled.header`
  padding: 0 var(--sGutter);
  height: ${DOCS_HEADER_HEIGHT};
  width: 100%;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: var(--primary);
  backdrop-filter: blur(10px);
  z-index: 4;
  box-shadow: 0 2px 4px rgba(3, 27, 78, 0.05);
  transition: transform 200ms ease-in-out;

  @media (max-width: ${S_BREAKPOINT}px) {
    justify-content: space-between;
    margin-bottom: 0;
  }

  [data-theme="dark"] & {
    background-color: var(--secondary);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  ${ToggleStyles}
`;

const ToggleContainer = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  padding: 0;
  color: inherit;
  margin-right: var(--l);
  display: none;

  @media (max-width: ${DOCS_NAV_BREAKPOINT}px) {
    display: block;
    cursor: pointer;
  }
`;

const DarkModeContainer = styled.div`
  @media (max-width: ${DOCS_NAV_BREAKPOINT}px) {
    display: none;
  }
`;

const HeaderLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

interface HeaderProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  headerContent?: ReactNode;
}

const Header = ({ open, setOpen, headerContent }: HeaderProps) => {
  const [isDarkMode, setIsDarkMode] = useTheme();

  return (
    <Container>
      <HeaderLeftContainer>
        <ToggleContainer
          aria-label={`${open ? "Close" : "Open"} Navigation`}
          aria-expanded={open}
          aria-haspopup
          onClick={() => setOpen(!open)}
        >
          <Menu />
        </ToggleContainer>
        <Logo />
      </HeaderLeftContainer>
      <SlideOver open={open} setOpen={setOpen} contents={headerContent} />
      <DarkModeContainer>
        <Toggle
          isToggled={isDarkMode}
          setIsToggled={() => setIsDarkMode(!isDarkMode)}
        />
      </DarkModeContainer>
    </Container>
  );
};

export default Header;
