import { useEffect, useState } from "react";

import { isSSR } from "./helpers";

interface WindowSize {
  width: number;
  height: number;
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: !isSSR ? window.innerWidth : 0,
    height: !isSSR ? window.innerHeight : 0,
  });

  useEffect(() => {
    if (!window) {
      return;
    }
    const handleResize = () =>
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });

    window.addEventListener("resize", handleResize);

    // Call handler immediately so state gets updated with initial window size
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
