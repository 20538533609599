import React, { ReactNode } from "react";

import { DocsPageContext } from "../types/pageContext";

import DocsLayout from "./DocsLayout";

interface LayoutProps extends DocsPageContext {
  children: ReactNode;
}

const Layout = ({ path, children, pageContext }: LayoutProps) => {
  if (pageContext.pageType === "docs") {
    return <DocsLayout path={path}>{children}</DocsLayout>;
  }
  return <div>{children}</div>;
};

export default Layout;
