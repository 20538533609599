import { createGlobalStyle } from "styled-components";
import normalize from "styled-normalize";
import reset from "styled-reset";

import libs from "./libs";

export const XS_BREAKPOINT = 400;
export const S_BREAKPOINT = 600;
export const M_BREAKPOINT = 1000;
export const ML_BREAKPOINT = 1250;
export const L_BREAKPOINT = 1400;

const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${reset}

  body {
    overflow-x: hidden;
  }

  :root {
    --xs: 0.125rem;
    --s: 0.25rem;
    --m: 0.5rem;
    --ml: 0.75rem;
    --l: 1rem;
    --xl: 1.5rem;
    --xxl: 2rem;
    --sText: 0.85rem;
    --inter: 'Inter', sans-serif;
    --bold: 600;
    --xBold: 800;

    --gutter: 5rem;
    --sGutter: 2.5rem;

    @media (max-width: ${M_BREAKPOINT}px) {
      --gutter: 1rem;
      --sGutter: 1rem;
    }

    --desktop: 770px;

    --vatsimBlue: #2483C5;
    --vatsimIndigo: #2B3990;
    --vatsimGreen: #29B473;
    --primary: #FFFFFF;
    --secondary: #FFFFFF;
    --opaqueSecondary: rgba(255, 255, 255, 0.65);
    --opaqueVatsimWhite: rgba(255, 255, 255, 0.85);
    --lightTextColor:#767379;
    --oppositeTextColor: #FFFFFF;
    --textColor: #0b2549;
    --nightTextColor: #000000;
    --skyBlue: #74CBFF; // remove
    --accent: #3d4fe0;
    --accentHover: #3343be;
    --accentOpaque:rgba(140, 150, 221, 0.2);
    --secondaryAccent: #fee9e1;
    --borderColor: rgba(0, 0, 0, 0.1);
    --tertiaryElement: #EBEDF0;
    --shadowXS: 0 5px 10px -3px rgba(50, 50, 93, 0.05),
    0 8px 8px -8px rgba(0, 0, 0, 0.2);
    --shadowS: 0 5px 14px -3px rgba(50, 50, 93, 0.075),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
    --shadowM: 0 5px 14px -3px rgba(50, 50, 93, 0.15),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
    --shadowXL: 0 13px 27px -3px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.15);

    font-family: var(--inter);
    color: var(--textColor);
    text-rendering: geometricPrecision;

    * {
      box-sizing: border-box;
    }

    a {
      text-decoration: none;
      transition: color 100ms ease-in-out;
    }
  }

  [data-theme="dark"] {
    --vatsimBlue: #2483C5;
    --vatsimIndigo: #2B3990;
    --vatsimGreen: #29B473;
    --primary: #191a1b;
    --secondary:#242526;
    --opaqueSecondary:rgba(36, 37, 38, 0.65);
    --opaqueVatsimWhite: rgba(255, 255, 255, 0.85);
    --lightTextColor:#FFFFFF;
    --oppositeTextColor: #0b2549;
    --textColor: #FFFFFF;
    --nightTextColor: #000000;
    --skyBlue: #74CBFF; // remove
    --accent: #4e60eb;
    --accentHover: #3343be;
    --accentOpaque:rgba(255, 255, 255, 0.075);
    --secondaryAccent: #fee9e1;
    --borderColor: rgba(255, 255, 255, 0.2);
    --tertiaryElement: #EBEDF0;
  }

  ${libs}
`;

export default GlobalStyle;
