import React, { createContext, ReactNode, useContext, useEffect } from "react";

import { useLocalStorage } from "../../hooks";
import { isSSR } from "../../hooks/helpers";

type Theme = [isDarkMode: boolean, setIsDarkMode: (x: boolean) => null];

export const ThemeContext = createContext<Theme>([false, () => null]);

export const useTheme = () => useContext(ThemeContext);

interface ThemeProviderProps {
  children: ReactNode;
}

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [isDarkMode, setIsDarkMode] = useLocalStorage("darkmode", false);

  useEffect(() => {
    if (isSSR) {
      return;
    }

    document.documentElement.setAttribute(
      "data-theme",
      isDarkMode ? "dark" : "light"
    );
  }, [isDarkMode]);

  return (
    <ThemeContext.Provider value={[isDarkMode, setIsDarkMode] as Theme}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
