import { Link } from "gatsby";
import React, { useCallback, useEffect, useState } from "react";
import { ChevronDown, ChevronRight } from "react-feather";
import styled from "styled-components";
import { useLocation } from "@reach/router";

import { hrefStyles } from "../../../styles";

const NavItem = styled.li`
  margin-top: var(--l);
  width: 100%;
`;

const Title = styled.button`
  padding: 0;
  margin-bottom: var(--m);
  border: none;
  background-color: transparent;
  font-weight: var(--bold);
  font-size: var(--sText);
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: var(--textColor);

  & > p {
    margin-left: var(--m);
  }
`;

const SubItems = styled.ul<{ open: boolean }>`
  height: ${({ open }) => (open ? "auto" : 0)};
  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? "auto" : "none")};
  margin-left: calc(var(--sGutter) * -1);
  max-width: 100%;
`;

const SubItem = styled.li<{ active: boolean }>`
  display: block;
  align-items: center;
  padding: var(--s) var(--m);
  margin-left: var(--sGutter);
  padding-left: var(--m);
  line-height: 1.25rem;
  font-weight: ${({ active }) => (active ? "var(--bold)" : "normal")};
  position: relative;
  text-transform: capitalize;
  max-width: 20rem;
  font-size: 0.875rem;
  border-left: ${({ active }) =>
    active ? "1px solid var(--accent)" : "1px solid var(--borderColor)"};
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100%);
  overflow: hidden;

  & > a > span {
    ${hrefStyles}
    color: ${({ active }) =>
      active ? "var(--accent)" : "var(--lightTextColor)"};
  }
`;

export interface Node {
  frontmatter: {
    title: string;
  };
  slug: string;
}

interface ItemProps {
  nodes: [string, Node[]];
}

const Item = ({ nodes }: ItemProps) => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const hasActiveRoute = nodes[1].find(({ slug }) => pathname.includes(slug));
    const isDocsHome = pathname === "/docs" || pathname === "/docs/";

    if (hasActiveRoute || isDocsHome) {
      setOpen(true);
    }
  }, [pathname]);

  const handleFocus = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <NavItem>
      <Title onClick={() => setOpen(!open)}>
        {open ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
        <p>{nodes[0]}</p>
      </Title>
      <SubItems open={open} aria-hidden={!open}>
        {nodes[1]
          ?.sort((a, b) => (a.frontmatter.title > b.frontmatter.title ? 1 : -1))
          .map(({ frontmatter, slug }, i) => (
            <SubItem
              active={pathname.replace(/\/$/, "").includes(slug)}
              key={i}
            >
              <Link onFocus={() => handleFocus()} to={"/docs/" + slug}>
                <span>{frontmatter.title}</span>
              </Link>
            </SubItem>
          ))}
      </SubItems>
    </NavItem>
  );
};

export default Item;
